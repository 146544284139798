import { AfterContentInit, afterNextRender, Component, HostListener, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { DataService } from '@Services/data.service';
import { CoreService, DestinyRecipesStateOptions, LoaderOptions, LoaderState, ManifestLoadingState } from '@Services/core.service';
import { AuthService } from '@Services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BungieApiResponse, LoadingComponent, LoadingError } from './types';
import { catchError, concatMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import { from, NEVER, Observable, of, throwError } from 'rxjs';
import { ModalService } from '@Services/modal.service';
import { VoltronService } from '@Services/voltron.service';
import { ImportedDataService } from '@Services/imported-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { logError } from './console';
import { WatchersService } from '@Services/watchers.service';
import { DropdownService } from '@Services/dropdown.service';
import { environment } from '@Env';
import { ToastrService } from 'ngx-toastr';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { HttpErrorResponse } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {
  errorMessage: string;
  version: string = environment.version + (environment.production ? '' : '-dev');
  firstLoad = true;
  tooltipSide: 'left' | 'right' = 'right';
  loadersActive = {};

  constructor(
    private dataService: DataService,
    public coreService: CoreService,
    public authService: AuthService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private voltron: VoltronService,
    private importedDataService: ImportedDataService,
    private translate: TranslateService,
    private titleService: Title,
    private meta: Meta,
    private watchersService: WatchersService,
    private dropdownService: DropdownService,
    @Inject(ViewContainerRef) private viewContainerRef,
    private toastrService: ToastrService,
    protected db: NgxIndexedDBService,
    private serviceWorker: SwUpdate
  ) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.get('version')) {
        this.coreService.setVersion(params.get('version'));
      } else if (params.get('versionClean')) {
        this.coreService.clearVersion();
      }
      if (params.get('clear')) {
        this.hardRefresh();
      }
    });
  }

  ngAfterContentInit() {
    this.browserInit();
  }

  browserInit() {
    if (this.coreService.isServer) { return; }
    this.serviceWorker.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          window.location.reload();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
    this.coreService.localStorageAvailable = true;
    this.migrateLocalStorage();
    if (!environment.production) {
      (window as any).dataService = this.dataService;
      (window as any).coreService = this.coreService;
    }
    this.modalService.setRootViewContainerRef(this.viewContainerRef);
    this.dropdownService.setRootViewContainerRef(this.viewContainerRef);
    this.coreService.loaderSub.subscribe(async (config) => {
      const loaders: Array<(options: LoaderOptions) => Observable<any>> = [];
      if (config.components.includes(LoadingComponent.AUTH) && (this.coreService.components[LoadingComponent.AUTH] !== true || config.options?.force)) {
        this.loadersActive[LoadingComponent.AUTH] = 'pending';
        loaders.push(this.loadAuthComponent.bind(this));
      }
      if (config.components.includes(LoadingComponent.MANIFEST) && (this.coreService.components[LoadingComponent.MANIFEST] !== true || config.options?.force)) {
        this.loadersActive[LoadingComponent.MANIFEST] = 'pending';
        loaders.push(this.loadManifestComponent.bind(this));
      }
      if (config.components.includes(LoadingComponent.INVENTORY) && (this.coreService.components[LoadingComponent.INVENTORY] !== true || config.options?.force)) {
        this.loadersActive[LoadingComponent.INVENTORY] = 'pending';
        loaders.push(this.loadInventoryComponent.bind(this));
      }
      if (config.components.includes(LoadingComponent.VOLTRON) && (this.coreService.components[LoadingComponent.VOLTRON] !== true || config.options?.force)) {
        this.loadersActive[LoadingComponent.VOLTRON] = 'pending';
        loaders.push(this.loadVoltronComponent.bind(this));
      }
      if (config.components.includes(LoadingComponent.COMMON) && (this.coreService.components[LoadingComponent.COMMON] !== true || config.options?.force)) {
        this.loadersActive[LoadingComponent.COMMON] = 'pending';
        loaders.push(this.loadCommonSettings.bind(this));
      }
      from(loaders).pipe(
        concatMap((loader) => loader(config.options)),
      ).subscribe({
        complete: () => {
          this.coreService.loadingState = LoaderState.NONE;
        },
        error: (error) => this.handleError(error)
      });
    });
    this.coreService.manifestLoadSub.subscribe((state) => {
      switch (state) {
        case ManifestLoadingState.DOWNLOADING:
          this.coreService.loadingState = LoaderState.DOWNLOADING_MANIFEST;
          break;
        case ManifestLoadingState.LOADING:
          this.coreService.loadingState = LoaderState.LOADING_MANIFEST;
          break;
      }
    });
    this.coreService.loadComponents([LoadingComponent.AUTH]);
    this.tooltipSide = this.coreService.getOption('global.tooltipSide') || 'right';
  }

  ngOnInit() {
    this.version = environment.version;

    this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route .firstChild) { route = route.firstChild; }
        return route;
      }),
      mergeMap((route) => route.data),
    ).subscribe((data) => {
      this.updateSEO(data);
    });
  }

  migrateLocalStorage() {
    const oldLocalStorageKeys: Array<{old: string, new?: keyof DestinyRecipesStateOptions, skipParse?: boolean}> = [
      { old: 'dr_sunset_mbr', new: 'global.membership', skipParse: true},
      { old: 'dr-xp-boosts', new: 'checklist.xpBoosts'},
      { old: 'dr-loot-showallperks', new: 'loot.showAllPerks'},
      { old: 'dr-score-display-config', new: 'global.scoreDisplay'},
      { old: 'dr-sunset-lang', new: 'global.lang'},
      { old: 'dr-loot-treated', new: 'loot.lastTreated'},
      { old: 'dr-vault-cleaner-config', new: 'vault.config'},
      { old: 'dr-counttrashasfreespace', new: 'checklist.countTrashAsFreeSpace'},
      { old: 'dr-counttrashasfreespace', new: 'checklist.countTrashAsFreeSpace'},
      { old: 'inventoryData', new: null },
    ];
    for (const value of oldLocalStorageKeys) {
      try {
        if (this.coreService.getLocalStorageItem(value.old)) {
          if (value.new) {
            if (value.skipParse) {
              this.coreService.setOption(value.new, this.coreService.getLocalStorageItem(value.old));
            } else {
              this.coreService.setOption(value.new, JSON.parse(this.coreService.getLocalStorageItem(value.old)));
            }
          }
          this.coreService.removeLocalStorageItem(value.old);
        }
      } catch (err) {
        this.coreService.removeLocalStorageItem(value.old);
      }
    }
  }

  updateSEO(data: any) {
    this.translate.get(data?.title || 'PAGE_TITLES.DEFAULT', this.translate.currentLang).subscribe((title) => {
      this.titleService.setTitle(title);
      this.meta.updateTag({property: 'og:title', content: title});
      this.meta.updateTag({name: 'twitter:title', content: title});
    });
    this.translate.get(data?.desc || 'PAGE_TITLES.DEFAULT_DESC', this.translate.currentLang).subscribe((desc) => {
      this.meta.updateTag({name: 'description', content: desc});
      this.meta.updateTag({property: 'og:description', content: desc});
      this.meta.updateTag({name: 'twitter:description', content: desc});
    });
  }

  loadAuthComponent(options?: LoaderOptions): Observable<void> {
    this.loadersActive[LoadingComponent.AUTH] = 'loading';
    if ( this.coreService.isServer) {
      this.loadersActive[LoadingComponent.AUTH] = 'done';
      return of();
    }
    return new Observable<void>((obs) => {
      this.coreService.loadingState = LoaderState.AUTHENTICATING;
      this.authService.init()
        .subscribe((loggedIn: boolean) => {
          obs.next();
          this.coreService.loadingComponentState.next({component: LoadingComponent.AUTH, loaded: true});
          this.loadersActive[LoadingComponent.AUTH] = 'done';
          obs.complete();
        }, (err) => {
          obs.error(new LoadingError(LoadingComponent.AUTH));
        });
    });
  }

  loadManifestComponent(options?: LoaderOptions): Observable<any> {
    this.loadersActive[LoadingComponent.MANIFEST] = 'loading';
    if ( this.coreService.isServer) {
      this.loadersActive[LoadingComponent.MANIFEST] = 'done';
      return of();
    }
    return this.dataService.loadManifest()
      .pipe(tap(() => {
        this.importedDataService.initManifest(this.dataService.manifest);
        this.coreService.loadingComponentState.next({component: LoadingComponent.MANIFEST, loaded: true});
        this.loadersActive[LoadingComponent.MANIFEST] = 'done';
      }));
  }

  loadInventoryComponent(options?: LoaderOptions): Observable<any> {
    this.loadersActive[LoadingComponent.INVENTORY] = 'loading';
    if (!this.authService.authenticated || this.coreService.isServer) {
      this.coreService.loadingComponentState.next({component: LoadingComponent.INVENTORY, loaded: false});
      this.loadersActive[LoadingComponent.INVENTORY] = 'done';
      return of(NEVER)  ;
    }
    this.coreService.loadingState = LoaderState.LOADING_PROFILE;
    if (this.dataService.loadingInventory) { return of(NEVER); }
    return this.dataService.loadCachedInventory().pipe(
      mergeMap((loadedCache) => {
        if (!this.dataService.inventoryData && loadedCache) {
          this.coreService.loadComponents([LoadingComponent.INVENTORY], { background: true });
          return of(this.dataService.inventoryData);
        } else {
          this.firstLoad = false;
          return this.dataService.getInventory(this.firstLoad || options?.force || this.watchersService.running);
        }}),
      tap(() => {
        this.watchersService.notify();
        this.coreService.loadingComponentState.next({component: LoadingComponent.INVENTORY, loaded: true});
        this.loadersActive[LoadingComponent.INVENTORY] = 'done';
      }),
      catchError((err: HttpErrorResponse) => {
        const bungieError: BungieApiResponse<any> = err.error;
        if (bungieError && bungieError.ErrorCode === 5 && bungieError.ErrorStatus === 'SystemDisabled') {
          this.toastrService.error('Destiny maintenance is in progress. Services are not available during this time.');
        }
        this.coreService.loadingComponentState.next({component: LoadingComponent.INVENTORY, loaded: false});
        this.loadersActive[LoadingComponent.INVENTORY] = 'done';
        return throwError(err);
      })
    );
  }

  loadVoltronComponent(options?: LoaderOptions): Observable<any> {
    this.loadersActive[LoadingComponent.VOLTRON] = 'loading';
    if ( this.coreService.isServer) {
      this.loadersActive[LoadingComponent.VOLTRON] = 'done';
      return of();
    }
    this.coreService.loadingState = LoaderState.LOADING_ROLLS;
    return this.voltron.loadVoltronData().pipe(
      tap(() => {
        this.coreService.loadingComponentState.next({component: LoadingComponent.VOLTRON, loaded: true});
        this.loadersActive[LoadingComponent.VOLTRON] = 'done';
      })
    );
  }

  loadCommonSettings(options?: LoaderOptions): Observable<any> {
    this.loadersActive[LoadingComponent.COMMON] = 'loading';
    if ( this.coreService.isServer) {
      this.loadersActive[LoadingComponent.COMMON] = 'done';
      return of();
    }
    this.coreService.loadingState = LoaderState.LOADING_COMMON_SETTINGS;
    return this.dataService.loadCommonSettings().pipe(
      tap(() => {
        this.coreService.loadingComponentState.next({component: LoadingComponent.COMMON, loaded: true});
        this.loadersActive[LoadingComponent.COMMON] = 'done';
      })
    );
  }

  handleError(error: LoadingError) {
    logError('[Loading Error] Could not load component', error.component, error);
    switch (error.component) {
      case LoadingComponent.AUTH:
        this.authService.logout();
        this.coreService.loadingState = LoaderState.NONE;
        this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {}});
        this.displayError('HOME_PAGE.COULD_NOT_AUTH', -1);
        break;
    }
  }

  displayError(message, timeout = 5000) {
    this.errorMessage = message;
    if (timeout > 0) {
      setTimeout(() => this.errorMessage = null, timeout);
    }
  }

  hardRefresh() {
    this.coreService.clearLocalStorage();
    this.db.deleteDatabase().subscribe(() => {
      window.location.href = window.location.href + '?v=' + Math.random();
    },  () => {
      window.location.href = window.location.href + '?v=' + Math.random();
    });
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event?.isTrusted && (event.target as any)?.nodeName === 'BODY' && event.code === 'KeyD' && event.shiftKey && !event.ctrlKey && !event.altKey) {
      this.coreService.enableDevMode();
    }
  }

  toggleTooltipOrientation() {
    this.tooltipSide = this.tooltipSide === 'left' ? 'right' : 'left';
    this.toastrService.info('Tooltip orientation changed to ' + this.tooltipSide + ' side');
    this.coreService.setOption('global.tooltipSide', this.tooltipSide);
  }

}
